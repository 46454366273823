import { camelToKebabCase } from '@/services/stringUtility'

const defaultComponent = () =>
    import(/* webpackChunkName: "schemaItemView" */ "@/features/schemas/views/SchemaItemView.vue");

// Define custom components here
const customComponents = {
    // example: () =>
    //     import(/* webpackChunkName: "example" */ "@/features/Example.vue"),
};

// Define custom static properties here
const customStaticProps = {
    // example: {
    //     tabs: [
    //         "tab1",
    //         "tab2"
    //     ]
    // },
};

const entities = [
    "person",
    "role",
    "user",
    "dealership",
    "yard"
];

export function hasItemPage(entityKey) {
    return entities.includes(entityKey) || Object.keys(customComponents).includes(entityKey);
}

// Generate route with given entity and custom component if exists
const generateRoute = (e) => ({
    path: `/${camelToKebabCase(e)}/:itemId`,
    name: e,
    staticProps: { entityKey: e, ...customStaticProps[e] },
    component: customComponents[e] || defaultComponent
});

// Create routes for both custom and default entities
const routes = [...Object.keys(customComponents), ...entities].map(generateRoute);

export default routes;
