
import schemaMixin from "@/features/schemas/mixins/schemaMixin";
import { getEntity } from "@/features/schemas/services/schemaProvider"
import dataTables from "@/features/schemas/dataTables";

export default {
    mixins:[schemaMixin],
    props: {
        form: {
            type: Function,
            default: null
        },
        disableExport: {
            type: Boolean,
            default: true
        },
        outlined: {
            type: Boolean,
            default: false
        },
        tableOnly: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            editedItem: null,
            searchText: "",
            listRequest: null
        };
    },

    computed: {
        cardClasses() {
            return {
                'flex-grow-1': true,
                'd-flex': true,
                'flex-column': true,
                'pa-2': true,
                'outlined-card': this.outlined
            }
        },
        entity() {
            return getEntity(this.entityKey);
        },
        tableComponent() {
            return dataTables.getComponent(this.entity);
        },
    },

    methods: {
        add() {
            this.editedItem = {};
        },
        edit(item) {
            this.editedItem = item;
        },
        async onItemSaved() {
            this.editedItem = null;
            if (this.$refs.schemaDataTable) {
                await this.$refs.schemaDataTable.refresh();
            }
        },
        setListRequest(request) {
            this.listRequest = request;
        }
    }
}
