import { connectToActionHub, closeActionHubConnection, subscribe } from "@/services/actionHub"

function getDefaultState() {
    return {
        connection: null,
        subscription: null,
        isLoaded: false
    };
}

const refreshActionsByEntityKey = {
}

const refreshEntityKeys = Object.keys(refreshActionsByEntityKey);
const refreshActions = Object.values(refreshActionsByEntityKey);

const state = getDefaultState;

const actions = {
    async load({ commit, dispatch }) {
        const promises = refreshActions.map(action => dispatch(action));
        await Promise.all(promises);

        const connection = await connectToActionHub();
        const subscription = subscribe(
            messages => dispatch("receiveMessages", messages),
            refreshEntityKeys);

        commit("subscription", subscription);
        commit("connection", connection);
        commit("isLoaded", true);
    },

    async receiveMessages({ dispatch }, messages) {
        // Get the unique entity keys from the received messages and then dispatch any relevant
        // actions to refresh the data.
        const entityKeys = new Set(messages.map(m => m.entityKey));

        const promises = refreshEntityKeys
            .filter(entityKey => entityKeys.has(entityKey))
            .map(entityKey => dispatch(refreshActionsByEntityKey[entityKey]));

        await Promise.all(promises);
    },

    clear({ commit, state }) {
        state.subscription.unsubscribe();
        closeActionHubConnection(state.connection);
        commit("clear");
    }
};

const getters = {
};

const mutations = {
    connection(state, connection) {
        state.connection = connection;
    },
    subscription(state, subscription) {
        state.subscription = subscription;
    },
    isLoaded(state, value) {
        state.isLoaded = value;
    },
    clear(state) {
        Object.assign(state, getDefaultState());
    }
};

export const data = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
