import { getEnum } from "./schemaProvider"
import { hasProperty } from "@/services/objectUtility";
import { isNullOrWhiteSpace, pascalToCamelCase } from "@/services/stringUtility";

export class FieldDependencyProvider {
    #dependenciesByType;

    constructor(context, useDefault = true) {
        const dependenciesByType = {}

        context.keys().forEach(filename => {
            const file = context(filename);

            const dependency = useDefault ?
                file.default :
                file;

            if(!dependency.types) {
                throw `Unable to find the 'types' property in ${filename}.`;
            }

            dependency.types.forEach(type => dependenciesByType[type] = dependency);
        });

        this.#dependenciesByType = dependenciesByType;
    }

    getDependency(schemaField) {
        // Entity fields are integers. We want to resolve these as the "entity" type.
        const type = this.getDependencyType(schemaField);
        return (
            this.#dependenciesByType[type] ??
            this.#dependenciesByType["default"]);
    }

    getDependencyType(schemaField) {
        if("dependsOn" in schemaField) {
            // If we're looking at a foreign key, then look for a dependency which handles that
            // specific type, otherwise fall back to the generic "entity" type.
            if (schemaField.type.includes("iEnumerable") || schemaField.type.includes("list`1")) {
                return "entities";
            }
            let entityType = schemaField.dependsOn.entityKey;
            return this.hasEntityDependency(entityType) ? entityType : "entity";
        }

        if ("subtype" in schemaField) {
            let subtype = pascalToCamelCase(schemaField.subtype.name);
            if (!isNullOrWhiteSpace(subtype) && this.hasEntityDependency(subtype)) {
                return subtype;
            }
        }

        // Remove trailing "?" from nullable types.
        let type = schemaField.type.replace(/\?$/, "");

        if(getEnum(type) !== null) {
            // If a specific dependency exists for this enum, then use it,
            // otherwise fall back to the generic "enum" type.
            if (type in this.#dependenciesByType && type !== "entity") {
                return type;
            }
            return "enum";
        }
        if (type.includes("[]")) {
            return "array";
        }
        if (type == "string" && !("maxLength" in schemaField)) {
            return "textArea";
        }

        return type;
    }

    hasEntityDependency(entityType) {
        return hasProperty(this.#dependenciesByType, entityType);
    }
}
