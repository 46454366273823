import { update } from "@/services/apiService";
import axios from "axios"

const state = {
    person: {},
    isLoaded: false
};

const getters = {
    selectedYardId: (state) => state.person.selectedYardId
};

const actions = {
    async load({ commit, rootGetters }) {
        const personId = rootGetters["authentication/personId"];
        const scope = rootGetters["authentication/getScope"]("get", "people");
        const person = (await axios.get(`/api/${scope}people/` + personId)).data;
        commit("setPerson", person);
        commit("loaded");
    },
    async updateSelectedYardId({ commit, state }, selectedYardId) {
        const updatedPerson = { ...state.person, selectedYardId };
        await update("people", updatedPerson);
        commit("setPerson", updatedPerson);
    }
};

const mutations = {
    setPerson(state, person) {
        state.person = person;
    },
    loaded(state) {
        state.isLoaded = true;
    },
};

export const yard = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
