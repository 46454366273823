<v-dialog v-model="dialog" width="unset" max-width="1100">
    <template #activator="{ on }">
        <v-card dark class="rounded-lg mr-2 mt-2">
            <v-img
                v-if="file.isImage"
                class="thumbnail-image rounded-lg"
                height="150"
                max-width="150"
                :src="'/api/Files/Download/'+file.id"
                v-on="on" />
            <div v-else class="thumbnail-file">
                <v-icon>
                    mdi-file-document
                </v-icon>
                <span class="my-auto mx-4 ">
                    {{ file.name }}
                </span>
            </div>
            <v-btn
                fab
                x-small
                absolute
                bottom
                left
                color="#00000099"
                class="my-6"
                @click="deleteFile(file.id)">
                <v-icon>
                    mdi-trash-can-outline
                </v-icon>
            </v-btn>
            <v-btn
                fab
                x-small
                absolute
                bottom
                right
                color="#00000099"
                class="my-6"
                @click="downloadFile(file)">
                <v-icon>
                    mdi-download
                </v-icon>
            </v-btn>
        </v-card>
    </template>
    <template>
        <v-card color="white">
            <v-card-title class="image-card-title">
                <span>{{ file.name }}</span>
                <v-spacer />
                <v-btn text @click="dialog = false">
                    Close
                </v-btn>
            </v-card-title>
            <v-img contain max-height="700" :src="'/api/Files/Download/'+file.id">
                <v-btn
                    class="mx-2 my-10"
                    absolute
                    bottom
                    left
                    small
                    fab
                    dark
                    color="#8c8c8c99"
                    @click="deleteFile(file.id)">
                    <v-icon dark color="red">
                        mdi-trash-can-outline
                    </v-icon>
                </v-btn>
            </v-img>
        </v-card>
    </template>
</v-dialog>

<script>
import axios from "axios";

export default {
    props: {
        file: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            dialog: false
        }
    },

    methods: {
        deleteFile(id) {
            if (!confirm("Are you sure you want to delete this item?")) {
                return;
            }

            this.$emit('delete', id);
            this.dialog = false;
        },

        async downloadFile(file) {
            let response = await axios
                .get(`/api/Files/Download/${file.id}`, { responseType: 'blob' })

            let blob = new Blob([response.data], { type: response.headers["content-type"] });

            let downloadUrl = window.URL.createObjectURL(blob);
            let link = document.createElement("a");

            if (typeof link.download === "undefined") {
                window.location.href = downloadUrl;
                return;
            }

            link.href = downloadUrl;
            link.download = file.name;
            document.body.appendChild(link);
            link.click();
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "@/assets/style/theme.scss";

    .thumbnail-image {
        cursor: pointer;
        border: 1px solid $color-grey-light;
    }

    .thumbnail-image:hover {
        -webkit-transform: scale(1.04); // For chrome
        transform: scale(1.04);
    }

    .thumbnail-file {
        display: flex;
        height: 150px;
        width: 150px;
    }

    .thumbnail-file > i {
        position: absolute;
        top: 12px;
        width: 100%;
        color: $color-grey-dark;
    }

    .thumbnail-file > span {
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        max-height: 4.5em;
        line-height: 1.5em;
    }

    .image-card-title {
        background-color: $color-grey-light;
    }

    .image-card-title > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 88%;
    }
</style>
