import { login, logout, currentUser } from "@/features/account/services/accountService";
import { goTo, currentRouteIsPublic } from "@/services/routeService";
import { handleError } from "@/services/errorUtility";
import { getEnum } from "@/features/schemas/services/schemaProvider";
import { camelToPascalCase } from "@/services/stringUtility"

function getDefaultState() {
    return {
        user: null,
        policies: null,
        isLoggedIn: false,
        isLoaded: false,
        loggingIn: false
    }
}

const state = getDefaultState;

const getters = {
    personId: (state) => {
        return state.user?.personId;
    },
    companyId: (state) => {
        return state.user?.companyId;
    },
    hasPolicy: state => policy => {
        return state.policies?.has(policy) ?? false;
    },
    getScope: state => (verb, key) => {
        const pascalVerb = camelToPascalCase(verb);
        const pascalKey = camelToPascalCase(key);

        let scope = getEnum("scope");
        if (!scope) {
            scope = {
                user: 1,
                company: 2,
                global: 3
            }
        }
        const scopes = Object.keys(scope).map(e => camelToPascalCase(e));
        for (const scope of scopes) {
            const pascalScope = camelToPascalCase(scope);
            const policy = `${pascalVerb}${pascalScope}${pascalKey}`;
            if (state.policies.has(policy)) {
                return pascalScope;
            }
        }

        return "Global";
    }
}

const actions = {
    async load({ commit, dispatch }) {
        await dispatch("refreshUser");
        commit("loaded");
    },

    async login({ commit, dispatch }, { emailAddress, password }) {
        try {
            commit("loggingIn", true);
            await login(emailAddress, password);
            await dispatch("refreshUser");
            goTo("/generate")
        }
        catch (error) {
            commit("clear", error);
            handleError(error);
        }
        finally {
            commit("loggingIn", false);
        }
    },

    async logout({ commit }) {
        await logout();
        commit("clear");

        if(!currentRouteIsPublic()) {
            goTo("/landing");
        }
    },

    async refreshUser({ commit }) {
        const user = await currentUser();

        if(user !== null) {
            commit("user", user);
        }

        // For public routes, we don't want to proceed with any login redirection
        if(currentRouteIsPublic()) {
            return;
        }
        // If we have no user, then we need to go to the login page.
        if(user == null) {
            goTo("/landing");
        }
    },

    setSettings({ commit, dispatch }, settings) {
        commit("settings", settings);
        dispatch("localisation/load", null, { root: true });
    },
};

const mutations = {
    user(state, user) {
        state.user = user;
        state.policies = new Set(user.policies);
        state.isLoggedIn = true;
    },
    settings(state, settings) {
        if(state.user) {
            Object.assign(state.user, settings);
        }
    },
    loaded(state) {
        state.isLoaded = true;
    },
    loggingIn(state, loggingIn) {
        state.loggingIn = loggingIn;
    },
    clear(state) {
        state.user = null;
        state.isLoggedIn = false;
        state.loggingIn = false;
    }
};

export const authentication = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
