<template>
    <v-autocomplete
        v-bind="$attrs"
        outlined
        :item-value="itemValue"
        :item-text="itemText"
        v-on="$listeners" />
</template>

<script>

export default {
    props: {
        itemValue: {
            type: String,
            default: "id"
        },
        itemText: {
            type: String,
            default: "label"
        }
    }
}
</script>
