import router from "@/routes";

const publicPaths = [
    "/landing",
    "/login",
    "/reset-password",
    "/request-reset-password",
    "/new-user-set-password",
    "/setup",
    "/privacy-policy",
    "/terms-of-service",
    "/fair-use-policy"
];

export function currentRouteIsPublic() {
    return routeIsPublic(router.currentRoute);
}

export function routeIsPublic(route) {
    return publicPaths.some(publicPath => {
        const routeSegments = route.path.split("/").filter(Boolean);
        const publicPathSegments = publicPath.split("/").filter(Boolean);

        if (routeSegments.length === 0 || publicPathSegments.length === 0) {
            return false;
        }

        return routeSegments[0] === publicPathSegments[0];
    });
}

export function goTo(path) {
    if (router.currentRoute.path !== path) {
        router.push(path);
    }
}

export default {
    goTo,
    routeIsPublic,
    currentRouteIsPublic
};
