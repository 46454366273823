let appTableView = () =>
    import(
        /* webpackChunkName: "schemaTableView" */ "@/features/schemas/views/SchemaTableView.vue");

export default [
    {
        path: "/action-logs",
        name: "actionLogs",
        staticProps: { entityKey: "actionLog" },
    },
    {
        path: "/colours",
        name: "colours",
        staticProps: { entityKey: "colour" },
    },
    {
        path: "/companies",
        name: "companies",
        staticProps: { entityKey: "company" },
    },
    {
        path: "/company-features",
        name: "companyFeatures",
        staticProps: { entityKey: "companyFeature" },
    },
    {
        path: "/dealerships",
        name: "dealerships",
        staticProps: { entityKey: "dealership" },
    },
    {
        path: "/features",
        name: "features",
        staticProps: { entityKey: "feature" },
    },
    {
        path: "/makes",
        name: "makes",
        staticProps: { entityKey: "make" },
    },
    {
        path: "/models",
        name: "models",
        staticProps: { entityKey: "model" },
    },
    {
        path: "/people",
        name: "people",
        staticProps: { entityKey: "person" },
    },
    {
        path: "/person-yards",
        name: "personYards",
        staticProps: { entityKey: "personYard" },
    },
    {
        path: "/settings",
        name: "settings",
        staticProps: { entityKey: "setting" },
    },
    {
        path: "/transmission-types",
        name: "transmissionTypes",
        staticProps: { entityKey: "transmissionType" },
    },
    {
        path: "/two-factor-codes",
        name: "twoFactorCodes",
        staticProps: { entityKey: "twoFactorCode" },
    },
    {
        path: "/users",
        name: "users",
        staticProps: { entityKey: "user" },
    },
    {
        path: "/user-roles",
        name: "userRoles",
        staticProps: { entityKey: "userRole" },
    },
    {
        path: "/vehicles",
        name: "vehicles",
        staticProps: { entityKey: "vehicle" },
    },
    {
        path: "/vehicle-ads",
        name: "vehicleAds",
        staticProps: { entityKey: "vehicleAd" },
    },
    {
        path: "/vehicle-features",
        name: "vehicleFeatures",
        staticProps: { entityKey: "vehicleFeature" },
    },
    {
        path: "/yards",
        name: "yards",
        staticProps: { entityKey: "yard" },
    },
    {
        path: "/yard-features",
        name: "yardFeatures",
        staticProps: { entityKey: "yardFeature" },
    },
].map(e => {
    return { ...e, component: appTableView };
});
